jQuery(function($) {


  $('[data-load-review]').unbind('click').bind('click', function() {
    const postId = $(this).attr('data-load-review');
    const container = $('[data-load-review-container]');
    const modal = $('[data-modal="review"]');


    getReview(postId, function(response) {
      container.html(response);
      modal.addClass('is-open');
    });
  });




  /**
   * function to get a review post by post id
   *
   * @param {int}     postId - Retrieves this post id from clicked element
   * @param {object}  container - Container div that will be filled with ajax response
   * @param {object}  modal - The modal element that will be triggered to show
   * @return {string} Returns HTML defined in '/template-parts/modals/review/review-content.php'
   *                  by function 'voys_ajax_load_review_handler' in functions.php
   */
  function getReview(postId, callback) {

    $.ajax({
      url: review_object.ajax_url,
      data: {
        'action': 'voys_ajax_load_review',
        'id': postId
      },
      type: 'POST',
      success: callback,
      error: function(error) {
        console.log(error);
      }
    });
  };

});
